<template>
  <div class="backLogin">
    <!--    <div class="redB fl"></div>-->
    <!--    <div class="grayB fr"></div>-->
    <div class="loginFoo">
      <div class="leftImg fl">
        <img src="../assets/login/leftImg.png" alt="" />
      </div>
      <el-form
        @submit.native.prevent
        :model="ruleForm2"
        :rules="rules2"
        ref="ruleForm2"
        class="demo-ruleForm login-container fl"
      >
        <div class="userlogo">
          <img src="../assets/login/loginlogo_03.png" alt="" />
        </div>
        <el-form-item prop="username" class="userInput">
          <label
            style="
              line-height: 1;
              margin-bottom: 8px;
              color: #333333;
              font-size: 12px;
              float: left;
            "
            >用户名</label
          >
          <el-input
            class="userName"
            type="text"
            v-model="ruleForm2.username"
            auto-complete="off"
            placeholder="请输入用户名"
            id="loginUsername"
          >
            <i
              slot="prefix"
              style="margin-left: 9px"
              class="icon iconfont icon-a-yhm-login"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" class="userInput">
          <label
            style="
              line-height: 1;
              margin-bottom: 8px;
              color: #333333;
              font-size: 12px;
              float: left;
            "
            >密码</label
          >
          <el-input
            class="passWord"
            type="password"
            show-password
            v-model="ruleForm2.password"
            auto-complete="off"
            placeholder="请输入登录密码"
            id="loginPassword"
          >
            <i
              slot="prefix"
              style="margin-left: 9px"
              class="icon iconfont icon-a-mm-login"
            ></i>
          </el-input>
        </el-form-item>

        <el-form-item style="width: 100%; margin-top: 50px; margin-bottom: 0">
          <el-button
            class="loginBtn"
            type="primary"
            style="width: 100%; border-radius: 4px"
            @click="handleSubmit2"
            :loading="logining"
            id="loginBtn"
          >
            <span v-if="!logining">登录</span>
            <span v-else>登录中...</span>
          </el-button>
        </el-form-item>
        <el-form-item prop style="text-align: center">
          <el-button
            size="mini"
            style="color: #606266"
            type="text"
            class="btn btn-link forgot"
            @click="forget"
            >忘记密码?</el-button
          >
        </el-form-item>
        <el-dialog
          class="findPassword"
          title="找回密码"
          :visible.sync="centerDialogVisible"
          width="30%"
          left
          :close-on-click-modal="false"
          :before-close="handleClose"
        >
          <span
            >系统在几十秒内向您发送一封密码重置邮件，如果在3分钟内还没收到这封邮件，请重试.</span
          >
          <el-form
            @submit.native.prevent
            :model="sendEmail"
            :rules="rules"
            ref="sendEmail"
          >
            <el-form-item prop="emailAddress" class="email">
              <el-input
                v-model="sendEmail.emailAddress"
                placeholder="请输入您的邮箱"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="dialog-footer" style="text-align: center">
            <el-button
              size="mini"
              :loading="sendlogining"
              type="primary"
              @click="send"
              >确 定</el-button
            >
            <el-button size="mini" @click="handleReset('sendEmail')" style=""
              >取 消</el-button
            >
          </div>
        </el-dialog>
      </el-form>
    </div>
    <img
      style="
        position: absolute;
        bottom: 10px;
        transform: translateX(-50%);
        left: 50%;
      "
      src="../assets/login/footerImg.png"
      alt=""
    />
  </div>
</template>

<script>
import { login, getCurrentUser, sendPasswordResetCode } from "../api/api";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      logining: false,
      sendlogining: false,
      centerDialogVisible: false,
      sendEmail: {
        emailAddress: "",
      },
      ruleForm2: {
        username: "",
        password: "",
        saveLogin: false,
      },
      rules: {
        emailAddress: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
      },
      rules2: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleReset(formName) {
      this.centerDialogVisible = false;
      this.$refs[formName].resetFields();
    },
    handleClose(done) {
      done();
      this.handleReset("sendEmail");
    },
    forget() {
      this.centerDialogVisible = true;
    },
    send() {
      this.$refs.sendEmail.validate((valid) => {
        if (valid) {
          this.sendlogining = true;
          sendPasswordResetCode(this.sendEmail).then((res) => {
            if (res.success) {
              this.sendlogining = false;
              this.centerDialogVisible = false;
              this.handleReset("sendEmail");
              this.$message.success(res.message);
            }
            this.sendlogining = false;
          });
        }
      });
    },

    handleSubmit2() {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          this.logining = true;
          login(this.ruleForm2).then((res) => {
            if (res.success == true) {
              Cookies.set("accessToken", res.result);
              getCurrentUser().then((res) => {
                if (res.success) {
                  let roles = []; //角色可能有多个
                  let departments = []; //组织框架可能有多个
                  res.result.roles.forEach((e) => {
                    roles.push(e.name);
                  });
                  res.result.departments.forEach((e) => {
                    departments.push(e.name);
                  });
                  this.setStore("roles", roles);
                  this.setStore("departments", departments);
                  this.setStore("permiss", res.result.permissions);
                  delete res.result.roles;
                  delete res.result.departments;
                  this.setStore("useInfo", res.result); 
                  this.setStore("popSchedule", res.result.userIndexInfo.popSchedule );
                  if(res.result.userIndexInfo.specifiedIndex){
                    this.$router.push({ path: res.result.userIndexInfo.specifiedIndex });
                    this.setStore("activeIndex", res.result.userIndexInfo.specifiedIndex );
                  }else{
                    this.$router.push({ path: "/Home" });
                  }
                  this.$store.dispatch("upDateSelfStatus", true);
                } else {
                  this.logining = false;
                }
              });
            } else {
              this.logining = false;
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.fl {
  float: left;
}
.login-container {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  padding: 60px;
  background: #fff;
  border: 1px solid #eaeaea;
  .title {
    margin: 0 auto 40px auto;
    text-align: center;
    color: #505458;
  }
  .remember {
    margin: 0 0 35px 0;
  }
}
.email {
  margin: 20px 0;
}

.email input {
  width: 80%;
}
</style>
<style scoped>
.loginFoo {
  box-shadow: 10px 5px 25px -5px rgba(51, 51, 51, 0.2);
  overflow: hidden;
  background-clip: padding-box;
  width: 850px;
  margin: auto;
  position: absolute;
  height: 500px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.backLogin {
  height: 100%;
  background: url("../assets/login/background1.png") 100%;
  background-size: 100%;
}
.grayB {
  width: 70%;
  height: 100%;
  /*background: #efefef;*/
}
.redB {
  width: 30%;
  height: 100%;
  /*background: #f73f3f;*/
}
.leftImg {
  width: 50%;
  height: 100%;
}
.leftImg img {
  width: 100%;
  height: 100%;
}
.userlogo img {
  width: 90%;
  margin-bottom: 30px;
}
</style>
<style>
.findPassword .el-dialog__header {
  background: #f6f6f6;
}
.userInput .el-input {
  height: 35px;
}
.userInput .el-input__inner {
  background: white;
  padding-left: 40px;
  line-height: 40px;
  height: 40px;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
</style>

